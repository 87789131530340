import React, { useState, useEffect } from "react";
import { IonPage, IonHeader, IonContent, IonToolbar } from "@ionic/react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useHistory } from "react-router-dom";
import DesktopHeader from "../../components/DesktopHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import { getLangLabel } from "../../utility/languageTranslator";

const EditProfile = () => {
  const history = useHistory();
  const [profileImage, setProfileImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null); // For showing preview
  const [langCode, setlangCode] = useState("en");
  const [error, setError] = useState("");
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    gender: "",
    dob: null,
    profileUrl: "", // Added to handle profile image URL
  });

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setUser(userDetails);
    setPreviewUrl(userDetails?.profileUrl || "./assets/icon/account.png"); // Set initial preview
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
  }, []);

  const handleChange = (e) => {
    setError("");
    if (e instanceof Date) {
      setUser((prevUser) => ({
        ...prevUser,
        dob: e,
      }));
    } else {
      const { name, value } = e.target;
      if (name === "name") {
        const alphabetRegex = /^[a-zA-Z\s]*$/;
        if (!alphabetRegex.test(value)) {
          return;
        }
      }

      if (name === "phone") {
        const phoneRegex = /^[0-9]*$/;
        if (!phoneRegex.test(value) || value.length > 12) {
          return;
        }
      }

      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        setError("Please upload a valid image.");
        return;
      }

      setProfileImage(file);
      setError("");
      const preview = URL.createObjectURL(file);
      setPreviewUrl(preview); // Set preview for selected image
    }
  };

  const handleUpdate = async () => {
    const { name, email, phone, gender, dob } = user;

    if (!name || !email || !phone || !gender || !dob) {
      setError(getLangLabel(langCode)?.requireFields);
      return false;
    }

    if (!user.id) return;
    let profileUrl = null;
    const updatedUser = { ...user };
    if (profileImage) {
      console.log("new file uploaded");
      const formData = new FormData();
      formData.append("upload", profileImage);
      try {
        const response = await HTTP_CLIENT(
          APIFY("/upload"),
          "POST",
          formData,
          {},
          "formdata"
        );

        if (response && response.uploaded) {
          if (response.data && typeof response.data === "object") {
            profileUrl = `${response.data.path}${response.data.filename}`;
            updatedUser.profileUrl = profileUrl;
            updateUserDetails(updatedUser);
            setError("");
          } else {
            console.error("Unexpected response data format:", response.data);
          }
        } else {
          console.error("Upload failed:", response.message);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      updateUserDetails(updatedUser);
    }
    // No image update, just user details
  };

  const updateUserDetails = (updatedUser) => {
    HTTP_CLIENT(APIFY(`/users/${user.id}`), "PUT", updatedUser)
      .then((res) => {
        if (res?.data) {
          const newDetails = res?.data;
          localStorage.setItem(
            "userDetails",
            JSON.stringify({ ...user, ...newDetails })
          );
          alert(getLangLabel(langCode)?.profileUpdated);
        }
      })
      .catch((err) => {
        console.error("Update failed:", err);
      });
  };

  return (
    <IonPage>
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav" style={{ background: "#fff" }}>
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon onClick={() => history.goBack()} />
              <img
                onClick={() => history.push("/settings")}
                alt="Settings"
                src={"./assets/icon/settings.png"}
                height={25}
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        <div className="container">
          <h2 style={{ paddingLeft: "25px" }}>
            {getLangLabel(langCode)?.editProfile}
          </h2>
          <div className="coverArea">
            <div
              className="profileDetails"
              style={{ justifyContent: "center" }}
            >
              <div className="profile-pic-container">
                <input
                  type="file"
                  id="profileImage"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <img
                  style={{ borderRadius: "50%", cursor: "pointer" }}
                  alt="Profile"
                  src={previewUrl || "./assets/icon/account.png"}
                  onClick={() =>
                    document.getElementById("profileImage").click()
                  }
                />
                <button
                  type="button"
                  className="edit-button"
                  onClick={() =>
                    document.getElementById("profileImage").click()
                  }
                >
                  <img alt="Edit" src={"./assets/icon/pencil.png"} />
                </button>
              </div>
            </div>
          </div>
          <div className="profile-additional-options">
            <ul>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.name}</label>
                  <input
                    type="text"
                    placeholder="Your name"
                    name="name"
                    value={user?.name || ""}
                    onChange={handleChange}
                  />
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.email}</label>
                  <input
                    type="email"
                    placeholder="Your email address"
                    name="email"
                    value={user?.email || ""}
                    onChange={handleChange}
                    disabled={true}
                  />
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.phoneNumber}</label>
                  <input
                    type="tel"
                    placeholder="Your phone number"
                    name="phone"
                    value={user?.phone || ""}
                    onChange={handleChange}
                  />
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.gender}</label>
                  <div className="genderRadioBtn">
                    <label htmlFor="male">
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        id="male"
                        onChange={handleChange}
                        checked={user?.gender === "male"}
                      />
                      <span>{getLangLabel(langCode)?.male}</span>
                    </label>
                    <label htmlFor="female">
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        id="female"
                        onChange={handleChange}
                        checked={user?.gender === "female"}
                      />
                      <span>{getLangLabel(langCode)?.female}</span>
                    </label>
                    <label htmlFor="others">
                      <input
                        type="radio"
                        name="gender"
                        value="others"
                        id="others"
                        onChange={handleChange}
                        checked={user?.gender === "others"}
                      />
                      <span>{getLangLabel(langCode)?.others}</span>
                    </label>
                  </div>
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.dob}</label>
                  <DatePicker
                    name="dob"
                    selected={new Date(user.dob)}
                    onChange={(date) => handleChange(date)}
                    dateFormat="d MMMM, yyyy"
                    maxDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select your birth date"
                    withPortal
                  />
                </div>
              </li>
              {error && (
                <p className="error-message" style={{ color: "red" }}>
                  {error}
                </p>
              )}
            </ul>
          </div>

          <div className="submitBtn">
            <button onClick={handleUpdate}>
              {getLangLabel(langCode)?.update}
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
