/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Share } from "@capacitor/share";
import { getLangLabel } from "../utility/languageTranslator";

const DesktopHeader = ({ history }) => {
  const [langCode, setlangCode] = useState("en");
  const [districtName, setDistrictName] = useState("");
  const [stateName, setStateName] = useState("");

  const [searchlocation, setSearchLocation] = useState(() => {
    const storedValue = localStorage.getItem("searchlocation");
    return storedValue ? storedValue === "true" : false;
  });

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("languageCode") || "en"
  );
  const Languageslist = [
    // { code: "", name: "Select Language" },
    { code: "en", name: "English" },
    { code: "hi", name: "Hindi" },
  ];

  const apiKey = "AIzaSyA8UPOL-NXIwjEzHwfXRcVvTICWQ64AQ6Q"; // Your API key

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language.code);
    localStorage.setItem("languageCode", language.code);
    window.location.reload();
  };

  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
  }, [langCode]);

  const phoneNumber = "+916289991595";
  const openWhatsApp = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareApp = async () => {
    await Share?.share({
      text: `Explore India with our travel app! Discover expert tips, curated itineraries, and must-see destinations for an unforgettable journey. Download now and start your adventure!
      1. Play Store- https://play.google.com/store/apps/details?id=chalo.bharatdekhen.app
      2. Apple Store- https://apps.apple.com/in/app/bharat-dekhen/id6478861610`,
    });
  };

  ///setting expiration time for the district value from API
  const setWithExpiration = (key, value, ttl) => {
    const now = new Date().getTime();
    const item = {
      value: value,
      expiry: now + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
    console.log("setting item", item);
  };
  ///
  ///get with expiration
  const getWithExpiration = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    console.log("getting item parsed", item);

    const now = new Date().getTime();

    if (now > item.expiry) {
      // If the item is expired, delete the item from storage and return null
      localStorage.removeItem(key);
      return null;
    }
    console.log("getting item parsed2", item.value);
    return item.value;
  };
  ///
  ///

  useEffect(() => {
    const printCurrentPosition = async () => {
      let getdistname = await getWithExpiration("districtget");
      let getstname = await getWithExpiration("stateget");

      setDistrictName(getdistname);
      setStateName(getstname);
      if (getdistname === null) {
        try {
          if (navigator.geolocation) {
            // check if permission is granted
            navigator.permissions
              .query({ name: "geolocation" })
              .then((permissionStatus) => {
                console.log("permissionStatus", permissionStatus);
                if (permissionStatus.state === "prompt") {
                  // permission already granted, get the current user's location
                  navigator.geolocation.getCurrentPosition(
                    async (position) => {
                      // save the geolocation coordinates in two variables
                      const { latitude, longitude } = position.coords;
                      // update the value of userlocation variable
                      console.log("latitude", latitude, "longitude", longitude);
                      const response = await fetch(
                        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
                      );
                      const data = await response.json();
                      console.log("test1", data.plus_code.compound_code);
                      if (data.status === "OK") {
                        let district = "";
                        let state = "";
                        const addressComponents =
                          data.results[0].address_components;

                        for (const component of addressComponents) {
                          if (
                            component.types.includes(
                              "administrative_area_level_3"
                            )
                          ) {
                            district = component.long_name;
                          }
                          if (
                            component.types.includes(
                              "administrative_area_level_1"
                            )
                          ) {
                            state = component.long_name;
                          }
                          if (district && state) break;
                        }
                        setDistrictName(district);
                        window.location.reload();
                        console.log("district==", district);
                        setStateName(state);
                        setWithExpiration("districtget", district, 900000);
                        setWithExpiration("stateget", state, 900000);
                      } else {
                        console.error("Failed to retrieve address information");
                      }
                    },

                    // if there was an error getting the user's location
                    (error) => {
                      console.error("Error getting user location:", error);
                    }
                  );
                } else if (permissionStatus.state !== "granted") {
                  alert(getLangLabel(langCode)?.geoPermission);

                  if (permissionStatus.state !== "granted") {
                    return null;
                  } else if (permissionStatus.state === "granted") {
                    printCurrentPosition();
                    setSearchLocation(true);
                  }
                }
              });
          } else {
            console.error("Geolocation is not supported by this browser.");
          }
        } catch (error) {
          console.error("Error getting current position:", error);
        } finally {
          setSearchLocation(true);
        }
      } else {
        console.log("district name already present");
      }
    };

    if (searchlocation === true && stateName == "" && districtName == "") {
      localStorage.setItem("searchlocation", searchlocation);
      printCurrentPosition();
    }
  }, [searchlocation]);

  return (
    <div className="deskTopHeader">
      <div className="headerLogo">
        <img
          alt=" "
          src="./assets/icon/logo.png"
          className=""
          onClick={() => history.push("/home")}
        />
      </div>

      <div className="navigationLinks">
        <ul>
          {/* <li onClick={() => history.push("/blogs")}>
            <img alt=" " src={"./assets/icon/blog.png"} height={30} />
            <span>{getLangLabel(langCode)?.option1}</span>
          </li> */}
          <li onClick={openWhatsApp}>
            <img alt=" " src={"./assets/icon/whatsapp.png"} height={30} />
            <span>{getLangLabel(langCode)?.option2}</span>
          </li>
          <li onClick={shareApp}>
            <img alt=" " src={"./assets/icon/share.png"} height={30} />
            <span>{getLangLabel(langCode)?.menuL7}</span>
          </li>
          <li>
            <select
              className="languageDropdown"
              value={selectedLanguage}
              onChange={(e) =>
                handleLanguageChange(
                  Languageslist.find((lang) => lang.code === e.target.value)
                )
              }
              onClick={(e) => e.stopPropagation()}
            >
              {Languageslist.map((language) => (
                <option key={language.code} value={language.code}>
                  {language.name}
                </option>
              ))}
            </select>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(DesktopHeader);
