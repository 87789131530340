import React, { useEffect, useState, useRef } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  useIonViewDidEnter,
  useIonViewDidLeave,
  IonItem,
  IonLabel,
  IonModal,
  IonTextarea,
  IonList,
  IonListHeader,
  IonAvatar,
  IonThumbnail,
  IonSkeletonText,
  IonLoading,
} from "@ionic/react";
import { useLocation, withRouter } from "react-router";
import moment from "moment";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelIcon from "@mui/icons-material/Cancel";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getFilteredData } from "../../modalconfig";
import { EditorState } from "draft-js";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import starOutline from "../../images/star_outline.png";
import downarrow from "../../images/star.png";
import profIcon from "../../images/account.png";
import Resizer from "react-image-file-resizer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import DesktopHeader from "../../components/DesktopHeader";
import {
  getCityInLang,
  getLangLabel,
  getLangState,
} from "../../utility/languageTranslator";
import { removeTagsAndStyles } from "../../utility/commonMethods";
import FooterMenu from "../../components/FooterMenu";
import MyTextToSpeach from "../../components/MyTextToSpeach";

function DisplayContent({ content }) {
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
}

const ParticularPlaceInformation = ({ history }) => {
  const sectionRef = useRef(null);
  const location = useLocation();
  const [details, setDetails] = React.useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [ReviewTxt, setReviewTxt] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [PlaceN, setPlaceN] = useState("");
  const [reviews, setReviews] = useState([]);
  const [data, setData] = useState();
  const [stateName, setStateName] = useState();
  const [cityName, setCityName] = useState();
  const editorState = EditorState.createEmpty();
  const [images, setImages] = useState([]);
  const [rating, setRating] = useState(null);
  const [serverImages, setServerImages] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [nearbyPlaces, setNearbyPlaces] = useState([]);
  const [netsts, Setnetsts] = useState(false);
  const [langCode, setlangCode] = useState("en");
  const [leftImg, setleftImg] = useState([]);
  const [speech, setSpeech] = useState("");
  const [reloadKey, setReloadKey] = useState(0);
  const [toolbarBg, setToolbarBg] = useState("rgba(255, 255, 255, 0)");
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [toolbarShadow, setToolbarShadow] = useState("none");
  const [selected, setSelected] = useState(false);
  const [iconColor, setIconColor] = useState("rgba(255, 255, 255, 1)");

  const [toolbarModalBg, setToolbarModalBg] = useState(
    "rgba(255, 255, 255, 0)"
  );
  const [lastScrollModalTop, setLastScrollModalTop] = useState(0);
  const [toolbarModalShadow, setToolbarModalShadow] = useState("none");
  const [iconModalColor, setIconModalColor] = useState(
    "rgba(255, 255, 255, 1)"
  );

  const [menuVisible, setMenuVisible] = useState({});
  const dropdownRef = useRef(null);
  const [isViewAllReviewOpen, setIsViewAllReviewOpen] = useState(false);
  const [user, setUser] = useState({});
  const [updateReviewId, setUpdateReviewId] = useState(false);
  const dropdownRefs = useRef([]);
  const swiperRef = useRef(null);

  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Place Details: ${details?.name}, ${details?.city}, ${details?.state}`,
        screenClass: "Place Details",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Place Details: ${details?.name}, ${details?.city}, ${details?.state}`,
          screen_class: "Place Details",
          additional_context: {
            place: details?.name,
            state: details?.state,
            city: details?.city,
          },
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };
  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    setUser(userDetails);
  }, [location.key]);
  useEffect(() => {
    if (details?.name) {
      setSelected(false);
      const staredValues =
        JSON.parse(localStorage.getItem("staredValues")) || [];
      const foundValue = staredValues?.find((value) => value === details?.name);
      if (foundValue) {
        setSelected(true);
      }
      setAnalyticsScreen();
    }
  }, [details?.name]);

  const getNearbyPlaces = async (latitude, longitude, placeId) => {
    try {
      const res = await HTTP_CLIENT(
        APIFY(
          `/places/nearPlaces?latitude=${latitude}&longitude=${longitude}&id=${placeId}`
        ),
        "GET"
      );
      setNearbyPlaces(res.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const goToNearbyPlace = (place) => {
    localStorage.removeItem("placeDetails");
    localStorage.setItem("placeDetails", JSON.stringify(place));
    setReloadKey((prevKey) => prevKey + 1);
    history.push("/particularplaceinformation");
  };
  const getData = async () => {
    setTimeout(() => {
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);

    const netAvail = localStorage.getItem("Networkstatus");
    Setnetsts(netAvail);

    let data = await JSON.parse(localStorage.getItem("placeDetails"));
    setStateName(data?.state);
    setCityName(data?.city);
    if (
      data?.location?.coordinates[1] &&
      data?.location?.coordinates[0] &&
      data._id
    )
      getNearbyPlaces(
        data?.location?.coordinates[1],
        data?.location?.coordinates[0],
        data._id
      );
    setDetails(data);
    setData({});
    setPlaceN(data.name);
    setShowLoading(false);
    if (netAvail !== "false") {
      const languageCode = localStorage.getItem("languageCode");
      setlangCode(languageCode || "en");
      getReviews();
      setleftImg(data?.img[0]);

      if (languageCode === "hi" && data?.eventdetails_lang?.hi) {
        const eventDetails = removeTagsAndStyles(data?.eventdetails_lang?.hi);
        setSpeech(eventDetails);
      } else {
        const eventDetails = removeTagsAndStyles(data.eventdetails);
        setSpeech(eventDetails);
      }
    }
  };
  useIonViewDidEnter(async () => {
    getData();
  }, [location.key]);
  useEffect(() => {
    if (reloadKey > 0) {
      getData();
    }
  }, [reloadKey]);

  const goBack = () => {
    history.goBack();
  };
  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleImageChange = async (e) => {
    setShowLoading(true);
    const files = Array.from(e.target.files); // Convert FileList to array
    const newImages = [];
    const newPreviewUrls = [];
    try {
      // Resize all images concurrently
      await Promise.all(
        files.map(async (file) => {
          try {
            const resizedFile = await new Promise((resolve) => {
              Resizer.imageFileResizer(
                file,
                200, // Width
                200, // Height
                "JPEG", // Format
                100, // Quality
                0, // Rotation
                (resizedFile) => {
                  console.log("Resized file:", resizedFile);
                  resolve(resizedFile);
                },
                "blob", // Output type
                200, // Max width (optional)
                200 // Max height (optional)
              );
            });

            if (resizedFile instanceof Blob) {
              newImages.push(resizedFile);
              const imageUrl = URL.createObjectURL(resizedFile); // Convert Blob to object URL
              newPreviewUrls.push(imageUrl);
            } else {
              console.log("Resized file is not a Blob:", resizedFile);
            }
          } catch (error) {
            console.log("Error resizing image:", error);
            throw error; // Rethrow error to be caught by outer catch block
          }
        })
      );

      setImages([...images, ...newImages]);
      setPreviewUrls([...previewUrls, ...newPreviewUrls]);
      setShowLoading(false);

      // Now upload the images to the server
      await uploadImgToApi(newImages);
    } catch (error) {
      console.error("Error handling image change:", error);
      setShowLoading(false);
      // Provide feedback to the user about the error
      alert(getLangLabel(langCode)?.imagesError);
    }

    // Reset the input value to allow the same file to be selected again
    document.getElementById("uploadInput").value = "";
  };

  const uploadImgToApi = async (imagesToUpload) => {
    const uploadedUrls = [];

    for (let i = 0; i < imagesToUpload.length; i++) {
      const formdata = new FormData();
      const image = imagesToUpload[i];
      const filename = `${new Date().toISOString()}-${i}.png`;

      Object.defineProperty(image, "name", {
        writable: true,
        value: filename,
      });

      formdata.append("upload", image, filename);

      try {
        console.log(formdata, "uploadedimages---");
        const response = await HTTP_CLIENT(
          APIFY("/upload"),
          "POST",
          formdata,
          {},
          "formdata"
        );

        if (response && response.uploaded) {
          if (response.data && typeof response.data === "object") {
            const url = `${response.data.path}${response.data.filename}`;
            console.log("Uploaded URL:", url);
            uploadedUrls.push(url);
            console.log("uploadedUrls===", uploadedUrls);
            setServerImages((prevUrls) => [...prevUrls, url]);
          } else {
            console.error("Unexpected response data format:", response.data);
          }
        } else {
          console.error("Upload failed:", response.message);
        }
      } catch (err) {
        console.log(err);
      }
    }

    return uploadedUrls;
  };

  ///Remove Image
  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
    setPreviewUrls(previewUrls.filter((_, index) => index !== indexToRemove));
    setServerImages(serverImages.filter((_, index) => index !== indexToRemove));
  };

  const handleCancel = () => {
    setIsOpen(false);
    setImages([]);
    setPreviewUrls([]);
    setServerImages([]);
    setReviewTxt("");
  };
  ///
  const getReviews = async () => {
    setReviews([]);
    let data = await JSON.parse(localStorage.getItem("placeDetails"));
    let Reviews = await getFilteredData("reviews", "reviewplace", data?.name);
    setReviews(Reviews);
  };

  useIonViewDidLeave(() => {
    setDetails({});
    setIsOpen(false);
  });

  const ReviewFn = async () => {
    setShowLoading(true);
    if (!ReviewTxt.trim()) {
      alert(getLangLabel(langCode)?.reviewNotEmpty);
      setShowLoading(false);
      return;
    } else if (rating === null) {
      alert(getLangLabel(langCode)?.giveRating);
      setShowLoading(false);
      return;
    } else {
      let data = {
        userId: user?.id,
        reviewplace: PlaceN,
        review: ReviewTxt,
        state: details?.state,
        city: details?.city,
        img: serverImages,
        rating: rating,
      };
      HTTP_CLIENT(
        APIFY(`/reviews/${updateReviewId ? updateReviewId : ""}`),
        updateReviewId ? "PUT" : "POST",
        data
      )
        .then(async (res) => {
          console.log("success", res);
          setIsOpen(false);
          setImages([]);
          setPreviewUrls([]);
          setServerImages([]);
          setReviewTxt("");
          setRating(null);
          setUpdateReviewId(null);
          getReviews();
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => {
          setShowLoading(false);
        });

      setIsOpen(false);
    }
  };

  const responsiveimg = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {}, [editorState]);

  const handleScroll = (event) => {
    const scrollTop = event.detail.scrollTop;
    const maxScroll = 50;
    const newOpacity = Math.min(1, scrollTop / maxScroll);

    setToolbarBg(`rgba(255, 255, 255, ${newOpacity})`);
    const newTextColor = `rgba(${255 * (1 - newOpacity)}, ${
      255 * (1 - newOpacity)
    }, ${255 * (1 - newOpacity)}, 1)`;
    setIconColor(newTextColor);
    setLastScrollTop(scrollTop);

    if (scrollTop > 50) {
      setToolbarShadow("rgb(136 136 136 / 28%) 0px 4px 6px");
    } else {
      setToolbarShadow("none");
    }
  };

  const handleModalScroll = (event) => {
    const scrollTop = event.detail.scrollTop;
    const maxScroll = 50;
    const newOpacity = Math.min(1, scrollTop / maxScroll);

    setToolbarModalBg(`rgba(255, 255, 255, ${newOpacity})`);
    const newTextColor = `rgba(${255 * (1 - newOpacity)}, ${
      255 * (1 - newOpacity)
    }, ${255 * (1 - newOpacity)}, 1)`;
    setIconModalColor(newTextColor);
    setLastScrollModalTop(scrollTop);

    if (scrollTop > 50) {
      setToolbarModalShadow("rgb(136 136 136 / 28%) 0px 4px 6px");
    } else {
      setToolbarModalShadow("none");
    }
  };

  const downloadData = () => {
    setSelected(true);
    const staredValues = JSON.parse(localStorage.getItem("staredValues")) || [];
    staredValues.push(details?.name);
    localStorage.setItem("staredValues", JSON.stringify(staredValues));
    const existingDownloads =
      JSON.parse(localStorage.getItem("myDownloads")) || [];
    const districtExists = existingDownloads?.some(
      (download) => download?.district === details?.name
    );
    if (districtExists) {
      alert(getLangLabel(langCode)?.alreadyDownloaded);
      return;
    }
    details.type = "place";
    const updatedArray = existingDownloads?.concat(details);
    localStorage.setItem("myDownloads", JSON.stringify(updatedArray));
    alert(getLangLabel(langCode)?.dataDownloaded);
  };
  const handleReviewDelete = (item) => {
    HTTP_CLIENT(APIFY(`/reviews/${item?._id}`), "DELETE", {})
      .then((data) => {
        getReviews();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleReviewEdit = (item) => {
    setIsOpen(true);
    setUpdateReviewId(item?._id);
    setPreviewUrls(item?.img || []);
    setServerImages(item?.img || []);
    setReviewTxt(item?.review);
    setRating(item?.rating);
  };
  const handleReviewReport = (item) => {
    if (!user) {
      alert(getLangLabel(langCode)?.reviewNotAllow);
    } else {
      alert(getLangLabel(langCode)?.reviewReport);
    }
  };
  const showMenu = (key) => {
    setMenuVisible((prev) => {
      // If the current dropdown (key) is already open, close it by setting it to false
      if (prev[key]) {
        return { [key]: false };
      }
      // Otherwise, open the current dropdown and close any other open dropdowns
      return { [key]: true };
    });
  };

  const handleCloseAllMenus = () => {
    setMenuVisible({});
  };

  const handleClickOutside = (event) => {
    Object.keys(dropdownRefs.current).forEach((key) => {
      if (
        dropdownRefs.current[key] &&
        !dropdownRefs.current[key].contains(event.target) && // Check if click is outside the dropdown
        !(swiperRef.current && swiperRef.current.contains(event.target)) // Check if click is inside the Swiper
      ) {
        setMenuVisible((prev) => ({
          ...prev,
          [key]: false, // Close the specific dropdown
        }));
      }
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuVisible]);

  const openViewReviewModal = () => {
    setIsViewAllReviewOpen(true);
  };

  const closeModal = () => {
    setIsViewAllReviewOpen(false);
  };

  return (
    <IonPage>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonHeader style={{ background: "#fff" }}>
        <IonToolbar
          className="transparentToolbar t-bar"
          style={{
            background: toolbarBg,
            transition: "0.3s ease",
            boxShadow: toolbarShadow,
          }}
        >
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon
                style={{ color: iconColor }}
                className=""
                onClick={goBack}
              />
              <div
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {selected ? (
                  <img
                    src={downarrow}
                    className="img-fluid"
                    style={{ width: "30px" }}
                    alt=""
                    onClick={() => {
                      alert(getLangLabel(langCode)?.alreadyDownloaded);
                    }}
                  />
                ) : (
                  <img
                    src={starOutline}
                    className="img-fluid"
                    style={{ width: "30px" }}
                    alt=""
                    onClick={downloadData}
                  />
                )}
              </div>
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent
        fullscreen
        onIonScroll={handleScroll}
        scrollEvents={true}
        className="backgroundGraphics"
      >
        {data ? (
          <>
            <div className="columnContainer">
              <div className="bannerLeft">
                <div className="labelDiv">
                  {details?.name_lang?.[langCode]
                    ? details?.name_lang?.[langCode]
                    : details?.name}
                </div>
                <Carousel
                  swipeable={true}
                  draggable={false}
                  showDots={false}
                  responsive={responsiveimg}
                  ssr={true} // means to render carousel on server-side.
                  infinite={false}
                  autoPlay={false}
                  autoPlaySpeed={3000}
                  keyBoardControl={true}
                  customTransition="all 1.2s ease 0s"
                  transitionDuration={1000}
                  containerClass="carousel-container carousel-list"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  slidesToSlide="1"
                  arrows={false}
                >
                  {details?.img?.length > 0 ? (
                    details?.img?.map((item) => (
                      <div>
                        <img
                          alt=" "
                          src={item}
                          style={{ height: "100vh", width: "100%" }}
                        />
                      </div>
                    ))
                  ) : (
                    <>
                      <div>
                        <img alt=" " src="./assets/icon/Districtbg.jpg" />
                      </div>
                    </>
                  )}
                </Carousel>
                <div className="labelDiv">
                  {details?.name_lang?.[langCode]
                    ? details?.name_lang?.[langCode]
                    : details?.name}
                </div>
              </div>
              <div className="bg-grey">
                <div className="container">
                  <div ref={sectionRef}></div>
                  <div
                    className="hasBannerOverlay"
                    style={{ position: "relative" }}
                  >
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={details?.img?.length > 1 ? true : false}
                      responsive={responsiveimg}
                      ssr={true} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all 0.2s ease 0s"
                      transitionDuration={300}
                      containerClass="carousel-container carousel-list forMbl"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                      slidesToSlide="1"
                      arrows={true}
                    >
                      {details?.img?.length > 0 ? (
                        details?.img?.map((item) => (
                          <div>
                            <img
                              alt=" "
                              src={item}
                              style={{ height: "280px", width: "100%" }}
                            />
                          </div>
                        ))
                      ) : (
                        <>
                          <div>
                            <img alt=" " src="./assets/icon/Districtbg.jpg" />
                          </div>
                        </>
                      )}
                    </Carousel>
                    <div
                      className="banr-box forMbl"
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        padding: "0 30px",
                        bottom: "30px",
                        left: 0,
                        right: 0,
                      }}
                    >
                      <h5
                        className=""
                        style={{
                          color: "#fff",
                          fontSize: "20px",
                          fontWeight: "500px",
                          display: "inline-block",
                          margin: "0 0 7px 0",
                        }}
                      >
                        {details?.name_lang?.[langCode]
                          ? details?.name_lang?.[langCode]
                          : details?.name}
                      </h5>
                    </div>
                  </div>
                  <div className="districtName">
                    <h4 onClick={() => history.push("/cityList")}>
                      {!showLoading &&
                        (langCode === "en"
                          ? stateName
                          : getLangState()?.[stateName])}
                    </h4>

                    <h4
                      onClick={() => history.push("/placeinformation")}
                      style={{ marginLeft: "10px" }}
                    >
                      {!showLoading &&
                        (langCode === "en"
                          ? cityName
                          : getCityInLang(cityName, langCode))}
                    </h4>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <div className="d_card historyArea">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h5
                          className="placeName"
                          style={{ fontSize: "15px", fontWeight: "bold" }}
                        >
                          {details?.name_lang?.[langCode]
                            ? details?.name_lang?.[langCode]
                            : details?.name}
                        </h5>
                        {<MyTextToSpeach speech={speech} langCode={langCode} />}
                      </div>
                      <DisplayContent
                        content={
                          details?.eventdetails_lang?.[langCode]
                            ? details?.eventdetails_lang?.[langCode]
                            : details?.eventdetails
                        }
                      />
                    </div>

                    {(details?.timing_mon_fri_from !== "" &&
                      details?.timing_mon_fri_to !== "") ||
                      (details?.timing_sat_sun_from !== "" &&
                        details?.timing_sat_sun_to !== "" && <h5>Timing</h5>)}

                    {details?.timing_mon_fri_from !== "" &&
                      details?.timing_mon_fri_to !== "" && (
                        <>
                          <p>
                            Monday-Friday :{" "}
                            {`${details?.timing_mon_fri_from}-${details?.timing_mon_fri_to}`}
                          </p>
                        </>
                      )}
                    {details?.timing_sat_sun_from !== "" &&
                      details?.timing_sat_sun_to !== "" && (
                        <>
                          <p>
                            Saturday-Sunday :{" "}
                            {`${details?.timing_sat_sun_from}-${details?.timing_sat_sun_to}`}
                          </p>
                        </>
                      )}
                    {details?.ticketAdult !== "" ||
                      (details?.ticketChild !== "" && <h5>Ticket price</h5>)}
                    {details?.ticketAdult !== "" && (
                      <p>Adult : Rs {details?.ticketAdult}/Person</p>
                    )}
                    {details?.ticketChild !== "" && (
                      <p>Children : Rs {details?.ticketChild}/Child</p>
                    )}
                    {details?.bestRoute && (
                      <>
                        {" "}
                        <h5>Best Route</h5>
                        <p>{details?.bestRoute}</p>
                      </>
                    )}
                    {details?.timeToVisit && (
                      <>
                        <h5>Best time to visit</h5>
                        <p>{details?.timeToVisit}</p>
                      </>
                    )}

                    <div>
                      {netsts === "true" && (
                        <>
                          <div className="d_card Reviews">
                            <h4>{getLangLabel(langCode)?.reviewLabel}</h4>
                            {reviews.length === 0 ? (
                              <p style={{ paddingLeft: "25px" }}>
                                {getLangLabel(langCode)?.notReviews}
                              </p>
                            ) : (
                              <div
                                className="out_card"
                                ref={swiperRef}
                                style={{ position: "relative" }}
                              >
                                <Swiper
                                  modules={[Navigation, Pagination, A11y]}
                                  className="carousel-container"
                                  navigation={true}
                                  pagination={{ clickable: true }}
                                  spaceBetween={20}
                                  slidesPerView={1}
                                  speed={500}
                                  autoHeight={true}
                                  dots={true}
                                  slidesOffsetAfter={0}
                                  slidesOffsetBefore={0}
                                  resistanceRatio={0}
                                  freeMode={false}
                                  onSlideChange={handleCloseAllMenus}
                                  breakpoints={{
                                    575: {
                                      slidesPerView: 1,
                                    },
                                    768: {
                                      slidesPerView: 2,
                                    },
                                    1025: {
                                      slidesPerView: 3,
                                    },
                                    1400: {
                                      slidesPerView: 3,
                                    },
                                  }}
                                  loop={false}
                                >
                                  {reviews?.slice(0, 5)?.map((item, index) => (
                                    <SwiperSlide
                                      key={index}
                                      className="carousel-item-padding-40-px"
                                    >
                                      <div
                                        className="inner_slide_card"
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                        }}
                                      >
                                        {console.log(
                                          "testing review.......",
                                          item?.userId,
                                          user?.id
                                        )}
                                        {Object.values(menuVisible).some(
                                          (isVisible) => isVisible
                                        ) && (
                                          <div
                                            className="dropdown-backdrop"
                                            onClick={handleCloseAllMenus}
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              right: 0,
                                              bottom: 0,
                                              zIndex: 3,
                                            }}
                                          ></div>
                                        )}
                                        <div
                                          className="userIcon"
                                          style={{ paddingRight: "10px" }}
                                        >
                                          <img
                                            src={
                                              item?.userDetails?.profileUrl ||
                                              profIcon
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div className="reviewContentRight">
                                          <div style={{ display: "flex" }}>
                                            <h5
                                              style={{
                                                fontWeight: "600",
                                                fontFamily: "sans-serif",
                                                fontSize: "16px",
                                                marginTop: "0",
                                                marginBottom: "0",
                                                marginRight: "10px",
                                              }}
                                            >
                                              {item?.userDetails?.name ||
                                                "Anonymous user"}
                                            </h5>
                                            <div
                                              className="dropDownMenuWrap"
                                              ref={dropdownRef}
                                              style={{ marginLeft: "auto" }}
                                            >
                                              <MoreVertIcon
                                                onClick={() => showMenu(index)}
                                              />
                                              {menuVisible[index] && (
                                                <div
                                                  className="dropDownMenu"
                                                  ref={(el) =>
                                                    (dropdownRefs.current[
                                                      index
                                                    ] = el)
                                                  }
                                                >
                                                  <ul>
                                                    {user?.id &&
                                                    item?.userId ===
                                                      user?.id ? (
                                                      <>
                                                        <li
                                                          onClick={() =>
                                                            handleReviewEdit(
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Edit
                                                        </li>
                                                        <li
                                                          onClick={() =>
                                                            handleReviewDelete(
                                                              item
                                                            )
                                                          }
                                                        >
                                                          Delete
                                                        </li>
                                                      </>
                                                    ) : (
                                                      <li
                                                        onClick={() =>
                                                          handleReviewReport(
                                                            item
                                                          )
                                                        }
                                                      >
                                                        Report
                                                      </li>
                                                    )}
                                                  </ul>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <Rating
                                            className="ratingStar"
                                            name="size-large"
                                            readOnly
                                            defaultValue={item?.rating || 0}
                                            size="large"
                                            emptyIcon={
                                              <StarIcon
                                                style={{ opacity: 0.55 }}
                                                fontSize="inherit"
                                              />
                                            }
                                          />
                                          <p
                                            style={{
                                              fontSize: "14px !important",
                                              fontWeight: "600",
                                              margin: "0",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            {moment(
                                              item?.createdAt || null
                                            ).format("DD/MM/YYYY")}
                                          </p>
                                          <p
                                            style={{
                                              margin: "0 0 20px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {item?.review}
                                          </p>
                                          {item.img && item.img.length > 0 && (
                                            <Swiper
                                              modules={[
                                                Navigation,
                                                Pagination,
                                                A11y,
                                              ]}
                                              className="carousel-container"
                                              navigation={true}
                                              pagination={{ clickable: true }}
                                              spaceBetween={20}
                                              slidesPerView={1}
                                              speed={500}
                                              autoHeight={true}
                                              dots={true}
                                              slidesOffsetAfter={0}
                                              slidesOffsetBefore={0}
                                              resistanceRatio={0}
                                              freeMode={false}
                                              loop={false}
                                            >
                                              {item.img.map(
                                                (imageUrl, imageIndex) => (
                                                  <SwiperSlide
                                                    key={imageIndex}
                                                    style={{ padding: 0 }}
                                                  >
                                                    <img
                                                      alt=""
                                                      src={imageUrl}
                                                      style={{
                                                        borderRadius: "30px",
                                                        maxHeight: "180px",
                                                        minHeight: "180px",
                                                        width: "100%",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </SwiperSlide>
                                                )
                                              )}
                                            </Swiper>
                                          )}
                                          {/* <p>
                                            11,000 people found this helpful
                                          </p> */}
                                          {/* <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <p
                                              style={{
                                                textAlign: "left",
                                                margin: 0,
                                              }}
                                            >
                                              Was this review helpful?
                                            </p>
                                            <div style={{ marginTop: "8px" }}>
                                              <button
                                                style={{
                                                  background: "#E87623",
                                                  borderRadius: "10px",
                                                  padding: "10px 24px",
                                                  color: "#fff",
                                                }}
                                              >
                                                Yes
                                              </button>
                                              <button
                                                style={{
                                                  marginLeft: "8px",
                                                  background: "#e1e1e1",
                                                  borderRadius: "10px",
                                                  padding: "10px 24px",
                                                  color: "#000",
                                                }}
                                              >
                                                No
                                              </button>
                                            </div>
                                          </div> */}
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                                <div className="allReviewModal">
                                  <strong onClick={openViewReviewModal}>
                                    View All
                                  </strong>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className="d_card"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h5
                              style={{
                                fontWeight: "600",
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                                marginBottom: 0,
                                marginTop: 0,
                              }}
                            >
                              {getLangLabel(langCode)?.alreadyVisited}
                            </h5>
                            <button
                              style={{
                                marginLeft: "auto",
                                background: "#E87623",
                                borderRadius: "10px",
                                padding: "10px 24px",
                                color: "#fff",
                              }}
                              onClick={() => {
                                if (!user) {
                                  alert(getLangLabel(langCode)?.reviewNotAllow);
                                } else {
                                  setIsOpen(true);
                                }
                              }}
                            >
                              {getLangLabel(langCode)?.review}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <IonModal
                  isOpen={isViewAllReviewOpen}
                  onDidDismiss={() => setIsViewAllReviewOpen(false)}
                >
                  <IonHeader style={{ background: "#fff" }}>
                    <IonToolbar
                      className="transparentToolbar t-bar"
                      style={{
                        background: toolbarModalBg,
                        transition: "0.3s ease",
                        boxShadow: toolbarModalShadow,
                      }}
                    >
                      <div className="top-nav">
                        <div className="nav_inner">
                          <ArrowBackIosNewRoundedIcon
                            style={{ color: iconModalColor }}
                            className=""
                            onClick={closeModal}
                          />
                          <ArrowBackIosNewRoundedIcon className="visibilityHidden" />
                        </div>
                      </div>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent
                    fullscreen
                    onIonScroll={handleModalScroll}
                    scrollEvents={true}
                    className="backgroundGraphics"
                  >
                    <div className="columnContainer">
                      <div className="bg-grey">
                        <div
                          className="hasBannerOverlay"
                          style={{ position: "relative" }}
                        >
                          {isViewAllReviewOpen && (
                            <Carousel
                              swipeable={true}
                              draggable={false}
                              showDots={true}
                              responsive={responsiveimg}
                              ssr={true} // means to render carousel on server-side.
                              infinite={false}
                              autoPlay={false}
                              autoPlaySpeed={1000}
                              keyBoardControl={true}
                              customTransition="all 0.2s ease 0s"
                              transitionDuration={300}
                              containerClass="carousel-container carousel-list forMbl"
                              removeArrowOnDeviceType={["tablet", "mobile"]}
                              dotListClass="custom-dot-list-style"
                              itemClass="carousel-item-padding-40-px"
                              slidesToSlide="1"
                              arrows={true}
                            >
                              {details?.img?.length > 0 ? (
                                details?.img?.map((item, index) => (
                                  <div key={index}>
                                    <img
                                      alt=" "
                                      src={item}
                                      style={{ height: "280px", width: "100%" }}
                                    />
                                  </div>
                                ))
                              ) : (
                                <div>
                                  <img
                                    alt=" "
                                    src="./assets/icon/Districtbg.jpg"
                                  />
                                </div>
                              )}
                            </Carousel>
                          )}
                          <div
                            className="banr-box forMbl"
                            style={{
                              position: "absolute",
                              zIndex: 1,
                              padding: "0 30px",
                              bottom: "30px",
                              left: 0,
                              right: 0,
                            }}
                          >
                            <h5
                              className=""
                              style={{
                                color: "#fff",
                                fontSize: "20px",
                                fontWeight: "500px",
                                display: "inline-block",
                                margin: "0 0 7px 0",
                              }}
                            >
                              {details?.name_lang?.[langCode]
                                ? details?.name_lang?.[langCode]
                                : details?.name}
                            </h5>
                          </div>
                        </div>
                        <div className="districtName">
                          <h4 onClick={() => history.goBack()}>
                            {!showLoading &&
                              (langCode === "en"
                                ? stateName
                                : getLangState()?.[stateName])}
                          </h4>

                          <h4
                            onClick={() => history.goBack()}
                            style={{ marginLeft: "10px" }}
                          >
                            {!showLoading &&
                              (langCode === "en"
                                ? cityName
                                : getCityInLang(cityName, langCode))}
                          </h4>
                        </div>
                        <div style={{ padding: "20px" }}>
                          <div
                            className="d_card Reviews"
                            style={{ marginTop: 0 }}
                          >
                            {reviews.map((item, index) => (
                              <div
                                key={`reviews-all-${index}`}
                                className="inner_slide_card"
                                style={{
                                  marginBottom: "15px",
                                  display: "flex",
                                  alignItems: "flex-start",
                                  padding: "20px 20px 38px",
                                  borderRadius: "30px",
                                  boxShadow: "0px 2px 12px 1px rgb(0 0 0 / 7%)",
                                  background: "white",
                                }}
                              >
                                {Object.values(menuVisible).some(
                                  (isVisible) => isVisible
                                ) && (
                                  <div
                                    className="dropdown-backdrop"
                                    onClick={handleCloseAllMenus}
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      zIndex: 3,
                                    }}
                                  ></div>
                                )}
                                <div
                                  className="userIcon"
                                  style={{ paddingRight: "10px" }}
                                >
                                  <img
                                    src={
                                      item?.userDetails?.profileUrl || profIcon
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="reviewContentRight">
                                  <div style={{ display: "flex" }}>
                                    <h5
                                      style={{
                                        fontWeight: "600",
                                        fontFamily: "sans-serif",
                                        fontSize: "16px",
                                        marginTop: "0",
                                        marginBottom: "0",
                                        marginRight: "10px",
                                      }}
                                    >
                                      {item?.userDetails?.name ||
                                        "Anonymous user"}
                                    </h5>
                                    <div
                                      className="dropDownMenuWrap"
                                      ref={dropdownRef}
                                      style={{ marginLeft: "auto" }}
                                    >
                                      <MoreVertIcon
                                        onClick={() =>
                                          showMenu(`modal${index}`)
                                        }
                                      />
                                      {menuVisible[`modal${index}`] && (
                                        <div
                                          className="dropDownMenu"
                                          ref={(el) =>
                                            (dropdownRefs.current[index] = el)
                                          }
                                        >
                                          <ul>
                                            {user?.id &&
                                            item?.userId === user?.id ? (
                                              <>
                                                <li
                                                  onClick={() =>
                                                    handleReviewEdit(item)
                                                  }
                                                >
                                                  Edit
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    handleReviewDelete(item)
                                                  }
                                                >
                                                  Delete
                                                </li>
                                              </>
                                            ) : (
                                              <li
                                                onClick={() =>
                                                  handleReviewReport(item)
                                                }
                                              >
                                                Report
                                              </li>
                                            )}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <Rating
                                    className="ratingStar"
                                    name="size-large"
                                    readOnly
                                    defaultValue={item?.rating || 0}
                                    size="large"
                                    emptyIcon={
                                      <StarIcon
                                        style={{ opacity: 0.55 }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                  <p
                                    style={{
                                      fontSize: "14px !important",
                                      fontWeight: "600",
                                      margin: "0",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {moment(item?.createdAt || null).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                  <p
                                    style={{
                                      margin: "0 0 20px",
                                      textAlign: "left",
                                    }}
                                  >
                                    {item?.review}
                                  </p>
                                  {item.img && item.img.length > 0 && (
                                    <Swiper
                                      modules={[Navigation, Pagination, A11y]}
                                      className="carousel-container"
                                      navigation={true}
                                      pagination={{ clickable: true }}
                                      spaceBetween={20}
                                      slidesPerView={1}
                                      speed={500}
                                      autoHeight={true}
                                      dots={true}
                                      slidesOffsetAfter={0}
                                      slidesOffsetBefore={0}
                                      resistanceRatio={0}
                                      freeMode={false}
                                      loop={false}
                                    >
                                      {item.img.map((imageUrl, imageIndex) => (
                                        <SwiperSlide
                                          key={imageIndex}
                                          style={{ padding: 0 }}
                                        >
                                          <img
                                            alt=""
                                            src={imageUrl}
                                            style={{
                                              borderRadius: "30px",
                                              maxHeight: "180px",
                                              minHeight: "180px",
                                              width: "100%",
                                              objectFit: "cover",
                                            }}
                                          />
                                        </SwiperSlide>
                                      ))}
                                    </Swiper>
                                  )}
                                  {/* <p>11,000 people found this helpful</p> */}
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p
                                      style={{
                                        textAlign: "left",
                                        margin: 0,
                                      }}
                                    >
                                      Was this review helpful?
                                    </p>
                                    <div style={{ marginTop: "8px" }}>
                                      <button
                                        style={{
                                          background: "#E87623",
                                          borderRadius: "10px",
                                          padding: "10px 24px",
                                          color: "#fff",
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        style={{
                                          marginLeft: "8px",
                                          background: "#e1e1e1",
                                          borderRadius: "10px",
                                          padding: "10px 24px",
                                          color: "#000",
                                        }}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </IonContent>
                </IonModal>

                <IonModal
                  isOpen={isOpen}
                  onDidDismiss={() => {
                    setIsOpen(false);
                    setReviewTxt("");
                  }}
                >
                  <IonHeader>
                    <IonToolbar className="t-bar">
                      <div className="top-nav" style={{ background: "#fff" }}>
                        <div
                          className="nav_inner"
                          style={{ position: "relative" }}
                        >
                          <ArrowBackIosNewRoundedIcon
                            onClick={() => handleCancel()}
                          />
                        </div>
                      </div>
                    </IonToolbar>
                  </IonHeader>
                  <IonContent className="backgroundGraphics">
                    <div className="columnContainer">
                      <div className="bg-grey">
                        <div style={{ padding: "20px" }}>
                          <h2
                            style={{
                              fontSize: "20px",
                              textAlign: "center",
                              marginTop: 0,
                            }}
                          >
                            {getLangLabel(langCode)?.review}{" "}
                            {details?.name_lang?.[langCode]
                              ? details?.name_lang?.[langCode]
                              : details?.name}
                          </h2>

                          <div className="d_card">
                            <div className="reviewBox">
                              <img src={user?.profileUrl || profIcon} alt="" />
                              <h5
                                style={{
                                  fontWeight: "600",
                                  fontFamily: "sans-serif",
                                  fontSize: "16px",
                                  marginTop: "0",
                                  marginBottom: "0",
                                }}
                              >
                                {user?.name || ""}
                              </h5>
                              <Rating
                                className="ratingStar"
                                name="size-large"
                                size="large"
                                value={rating} // Controlled value
                                onChange={handleRatingChange}
                                icon={<StarRoundedIcon fontSize="inherit" />}
                                emptyIcon={
                                  <StarOutlineRoundedIcon fontSize="inherit" />
                                }
                              />

                              <div className="reviewInputArea">
                                <IonTextarea
                                  value={ReviewTxt}
                                  placeholder={
                                    getLangLabel(langCode)?.enterReview
                                  }
                                  onIonChange={(e) =>
                                    setReviewTxt(e.target.value)
                                  }
                                ></IonTextarea>
                                <label
                                  htmlFor="uploadInput"
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    right: 0,
                                    top: "15px",
                                    zIndex: "99",
                                  }}
                                >
                                  <AddAPhotoOutlinedIcon />
                                  <input
                                    id="uploadInput"
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    onChange={handleImageChange}
                                    style={{ display: "none" }}
                                  />
                                </label>
                              </div>

                              <div className="reviewImgs">
                                {previewUrls?.map((url, index) => (
                                  <div
                                    key={`prevImg${index}`}
                                    className="imgItem"
                                    style={{
                                      background: `url(${url})`,
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                    }}
                                  >
                                    <CancelIcon
                                      style={{
                                        color: "#000",
                                        position: "absolute",
                                        top: 2,
                                        right: 2,
                                        background: "#fff",
                                        borderRadius: "50%",
                                      }}
                                      onClick={() => handleRemoveImage(index)}
                                    />
                                  </div>
                                ))}
                              </div>

                              <div style={{ marginTop: "8px" }}>
                                <button
                                  onClick={() => ReviewFn()}
                                  style={{
                                    background: "#E87623",
                                    borderRadius: "10px",
                                    padding: "10px 24px",
                                    color: "#fff",
                                  }}
                                >
                                  {getLangLabel(langCode)?.submit}
                                </button>
                                <button
                                  onClick={() => handleCancel()}
                                  style={{
                                    marginLeft: "8px",
                                    background: "#ababab",
                                    borderRadius: "10px",
                                    padding: "10px 24px",
                                    color: "#fff",
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                          <p style={{ fontSize: "11px" }}>
                            {getLangLabel(langCode)?.reviewFooter}
                          </p>
                        </div>
                      </div>
                    </div>
                  </IonContent>
                </IonModal>
                {!details?.type?.includes("Suggested Itinerary") && (
                  <div className="container">
                    {netsts === "true" && (
                      <>
                        <div style={{ padding: "20px" }}>
                          <IonLabel
                            style={{
                              paddingLeft: "25px",
                              fontWeight: "700",
                              fontFamily: "sans-serif",
                              marginBottom: 10,
                              display: "block",
                              fontSize: "20px",
                            }}
                          >
                            {getLangLabel(langCode)?.nearPlaces}
                          </IonLabel>
                          <div className="">
                            <div className="img-card">
                              <div className="">
                                <div className="gridCard">
                                  {nearbyPlaces.length === 0 ? (
                                    <p style={{ paddingLeft: "25px" }}>
                                      {getLangLabel(langCode)?.noNearPlaces}
                                    </p>
                                  ) : (
                                    nearbyPlaces.map((place, index) => (
                                      <div key={index} className="cardItem">
                                        <div
                                          className="caros-card"
                                          style={{
                                            paddingBottom: "10px",
                                            marginBottom: "25px",
                                          }}
                                          onClick={() => goToNearbyPlace(place)}
                                        >
                                          <img alt=" " src={place?.img?.[0]} />
                                          <h5>
                                            {place?.name_lang?.[langCode]
                                              ? place?.name_lang?.[langCode]
                                              : place?.name}
                                          </h5>
                                          <div>
                                            <h6
                                              style={{
                                                color: "black",
                                                textAlign: "left",
                                                padding: "0 25px",
                                                display: "flex",
                                                alignItems: "flex-start",
                                                fontSize: "14px",
                                                marginTop: 0,
                                              }}
                                            >
                                              <LocationOnIcon />{" "}
                                              {langCode === "en"
                                                ? `${place.city}, ${place.state}`
                                                : `${getCityInLang(
                                                    place.city,
                                                    langCode
                                                  )}, ${
                                                    getLangState()?.[
                                                      place.state
                                                    ]
                                                  }`}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="container">
              <div className="ion-padding custom-skeleton">
                <IonSkeletonText animated style={{ width: "60%" }} />
                <IonSkeletonText animated />
                <IonSkeletonText animated style={{ width: "88%" }} />
                <IonSkeletonText animated style={{ width: "70%" }} />
                <IonSkeletonText animated style={{ width: "60%" }} />
              </div>
              <IonList>
                <IonListHeader>
                  <IonLabel>
                    <IonSkeletonText animated style={{ width: "20%" }} />
                  </IonLabel>
                </IonListHeader>
                <IonItem>
                  <IonAvatar slot="start">
                    <IonSkeletonText animated />
                  </IonAvatar>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </p>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonThumbnail slot="start">
                    <IonSkeletonText animated />
                  </IonThumbnail>
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </p>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
                <IonItem>
                  <IonSkeletonText
                    animated
                    style={{ width: "27px", height: "27px" }}
                    slot="start"
                  />
                  <IonLabel>
                    <h3>
                      <IonSkeletonText animated style={{ width: "50%" }} />
                    </h3>
                    <p>
                      <IonSkeletonText animated style={{ width: "80%" }} />
                    </p>
                    <p>
                      <IonSkeletonText animated style={{ width: "60%" }} />
                    </p>
                  </IonLabel>
                </IonItem>
              </IonList>
            </div>
          </>
        )}
      </IonContent>
      <FooterMenu history={history} langCode={langCode} />
    </IonPage>
  );
};

export default withRouter(ParticularPlaceInformation);
